.email-templates {
  .template {
    background: white;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 20px;

    &__content {
      margin-top: 10px;
    }

    label {
      padding-right: 5px;
      color: #646777;
    }

    textarea {
      width: 100%;
      margin-top: 10px;
      border: 1px solid #f2f4f7;

      &:focus {
        outline: none;
      }
    }

    .wrapper-btn {
      display: flex;
      justify-content: flex-end;
    }

    input {
      border: 1px solid #f2f4f7;
      color: #646777;
      padding: 5px 0 5px 5px;
      font-size: 12px;
      height: 32px;
      transition: border 0.3s;
      background: transparent;
      width: 100%;
      max-width: 250px;

      &:focus {
        outline: none;
      }
    }

    table {
      table-layout: auto;
      width: 100%;
    }
  }
}